import React, {Component, useContext} from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import {Snackbar} from "@material-ui/core";
import './App.scss';
import {AppContext} from "./Contexts";

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Auth
const Login = React.lazy(() => import('./components/Auth/Login'));
const ForgotPassword = React.lazy(() => import('./components/Auth/ForgotPassword/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./components/Auth/ResetPassword/ResetPassword'));

const App = () => {
  const context = useContext(AppContext);
  return (
    <>
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
            <Route exact path="/forgot-password" name="Forgot Password" render={props => <ForgotPassword {...props}/>} />
            <Route exact path="/reset-password" name="Reset Password" render={props => <ResetPassword {...props}/>} />
            <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} />
          </Switch>
        </React.Suspense>
      </HashRouter>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={context.toggle}
        onClose={() => context.setToggle(false)}
        message={context.message}
      />
    </>
  );
};

export default App;
